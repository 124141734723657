// BOOTSTRAP VARIABLE OVERIDES
$primary: #27004b;
$secondary: #dfd0f3;
$danger: #dc3545;
$info: #21b0ff;
$link-color: #1272a6;
$line-height-base: 1.2 !default;

// CUSTOM VARIABLES
$tertiary: #a68dbe;
$level-1: #e14f4f;
$level-2: #af4f9d;
$level-3: #267dad;
$level-4: #d95195;
$neutral: #f0eee9;
$active: #21b0ff;

// GLOBAL CSS VARIABLES
:root {
  --gl-grey: #7b7286;

  --gl-level-1: #{$level-1};
  --gl-level-2: #{$level-2};
  --gl-level-3: #{$level-3};
  --gl-level-4: #{$level-4};
  --gl-level-4-bg: #{darken($level-4, 10%)};

  --gl-primary: #27004b;
  --gl-secondary: #dfd0f3;
  --gl-tertiary: #a68dbe;
  --gl-active: #21b0ff;
  --gl-danger: #dc3545;
  --gl-gold: #fcb638;

  --gl-secondary-rgb: 223, 208, 243;
  --gl-highlight-pink: #e81aca;
}

// NG-SELECT theme
@import "~@ng-select/ng-select/themes/default.theme.css";

// Video.js
@import "video.js/src/css/video-js.scss";

// BOOTSTRAP OVERRIDES
@import "scss/variables";

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "bootstrap-icons/font/bootstrap-icons";

@import "scss/font-greycliffcf";
@import "~@ng-select/ng-select/themes/default.theme.css";

// Import Codox Styling
@import url("https://cdn1.codox.io/lib/2.x/css/wave.client.css");

h1 {
  font-weight: 600;
}

a {
  color: #1272a6;
}

a:hover {
  color: var(--gl-active);
}

.btn-link:hover {
  color: var(--gl-tertiary);
}

html,
body {
  height: 100%;
}

html {
  font-size: 62.5%;
}

body {
  background: #f0eeea;
  font-size: 1.6rem;
}

.bg-primary {
  background-color: var(--gl-primary) !important;
}

.bg-level-1 {
  background-color: var(--gl-level-1) !important;
}

.bg-level-2 {
  background-color: var(--gl-level-2) !important;
}

.bg-level-3 {
  background-color: var(--gl-level-3) !important;
}

.bg-level-4 {
  background-color: var(--gl-level-4-bg) !important;
}

.bg-tertiary {
  background-color: $tertiary !important;
}

.bg-neutral {
  background-color: $neutral !important;
}

.bg-danger {
  background-color: $danger !important;
}

.bg-grey {
  background-color: var(--gl-grey) !important;
}

.border-secondary {
  border-color: $secondary !important;
}

.border-tertiary {
  border-color: $tertiary !important;
}

.border-highlight-pink {
  border-color: var(--gl-highlight-pink);
}

.border-warning {
  border-color: $warning !important;
}

.color-primary {
  color: $primary !important;
}

.color-secondary {
  color: $secondary !important;
}

.color-tertiary {
  color: $tertiary !important;
}

.color-gold {
  color: var(--gl-gold) !important;
}

.color-neutral {
  color: $neutral;
}

.color-success {
  color: $success;
}

.color-danger {
  color: $danger;
}

.color-warning {
  color: $warning;
}

.color-highlight-pink {
  color: var(--gl-highlight-pink);
}

.color-active,
.active {
  color: $active;
}

.color-gray {
  color: var(--bs-gray-500);
}

.color-dark-gray {
  color: var(--bs-gray-700);
}

.color-white {
  color: white;
}

// Font sizes, weights

.fs-07 {
  font-size: 0.7rem;
}

.fs-08 {
  font-size: 0.8rem;
}

.fs-09 {
  font-size: 0.9rem;
}

.fs-1 {
  font-size: 1rem;
}

.fs-11 {
  font-size: 1.1rem;
}

.fs-12 {
  font-size: 1.2rem;
}

.fs-13 {
  font-size: 1.3rem;
}

.fs-14 {
  font-size: 1.4rem;
}

.fs-15 {
  font-size: 1.5rem;
}

.fs-16 {
  font-size: 1.6rem;
}

.fs-17 {
  font-size: 1.7rem;
}

.fs-18 {
  font-size: 1.8rem;
}

.fs-19 {
  font-size: 1.9rem;
}

.fs-2 {
  font-size: 2rem;
}

.fs-21 {
  font-size: 2.1rem;
}

.fs-22 {
  font-size: 2.2rem;
}

.fs-23 {
  font-size: 2.3rem;
}

.fs-24 {
  font-size: 2.4rem;
}

.fs-25 {
  font-size: 2.5rem;
}

.fs-3 {
  font-size: 3rem;
}

.fs-4 {
  font-size: 4rem;
}

.fs-35px {
  font-size: 35px;
}

.fw-200 {
  font-weight: 200;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

.form-label {
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

// CARD STYLES

.card-header {
  background-color: #ffffff;
  border: 0;
  border-radius: 0.5rem;
}

.card-title {
  font-weight: 800;
  font-size: 1.6rem;
  cursor: pointer;
}

.card-title i {
  font-size: 1.1rem;
}

.card-tools {
  font-size: 1.1rem;
  font-weight: 200;
}

.card-tools a {
  text-decoration: none;
}

.card-tools a:hover {
  text-decoration: underline;
}

.card-body::-webkit-scrollbar {
  width: 10px;
}

.card-body::-webkit-scrollbar-track {
  background: $secondary;
  border-radius: 4px;
}

.card-body::-webkit-scrollbar-thumb {
  background: var(--bs-light);
  border-radius: 4px;
  border: 1px solid $gray-400;
}

.card-body::-webkit-scrollbar-thumb:hover {
  background: $tertiary;
}

.datePickerRow {
  max-width: 350px;
  margin: 1rem 0px 0px 0px;

  width: 100%;

  .ngb-dp-arrow {
    width: 0rem;
  }

  .ngb-dp-navigation-select {
    padding: 1rem 0;

    .form-select {
      font-size: 1.25rem;
      margin-left: 0.5rem;
      background-position: right 0.5rem center;
    }
  }
  .ngb-dp-month {
    width: 100%;
    font-size: 1.5rem !important;

    .ngb-dp-month-name {
      font-size: 1.5rem !important;
    }
  }
  .ngb-dp-day,
  .ngb-dp-weekday,
  .ngb-dp-week-number {
    width: 14.25%;
    height: 3rem;
    display: flex;
    justify-content: center;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-notallowed {
  cursor: not-allowed;
}

.disabled {
  pointer-events: none;
}

.ng-select.growelab .ng-select-container {
  border-color: var(--gl-tertiary);
}

.noTop {
  margin-top: 0px;
}

// DATE PICKER STYLES

.datepicker-popup {
  .ngb-dp-arrow {
    width: 0rem;
  }
  .ngb-dp-navigation-select {
    padding: 0.5rem 0;
    .form-select {
      font-size: 1.6rem;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto;
      height: auto;
      width: auto;
      margin-left: 0.5rem;
      background-position: right 0.5rem center;
    }
  }
  .ngb-dp-month {
    width: 100%;
  }
  .ngb-dp-day,
  .ngb-dp-weekday,
  .ngb-dp-week-number {
    margin: 2px;
    width: 3rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal !important;
  }
  .ngb-dp-weekday {
    font-weight: 700;
  }
}

app-daterange-picker {
  margin-top: 0px !important;
}

// TAB STYLES
.nav-tabs {
  .nav-link {
    font-weight: 500;
    font-size: larger;
    color: var(--gl-grey);
    &.active {
      color: var(--gl-primary);
      font-weight: 600;
    }
    &.disabled {
      font-weight: 300;
    }
  }
}

// BUTTON STYLES
.btn-primary:hover {
  background-color: #420080;
}

.btn-link {
  color: var(--gl-primary);
}

.btn-delete {
  border: none;
  background: transparent;
  color: var(--gl-secondary);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    color: var(--gl-danger);
  }
}

// remove default button styles
.btn-reset {
  background: transparent;
  border: none;
}

.btn-next {
  font-size: 2rem;
  font-weight: 700;
  padding-right: 2rem;
  padding-left: 2rem;
}

.btn-checkbox {
  border: solid 1px transparent;
  background: var(--bs-gray-300);
  border-radius: 50%;
  cursor: pointer;
  display: block;
  width: 20px;
  min-width: 20px;
  height: 20px;
  padding: 5px 0;
  position: relative;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:hover {
    border: solid 1px var(--gl-primary);
  }
  &.checked {
    background: #fff;
    border-color: var(--gl-active);
    color: var(--gl-active);
    i {
      font-size: 2rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

// BOOTSTRAP POPOVER STYLES
.popover-lg {
  max-width: 450px;
  min-width: 360px;
  .popover-header {
    background-color: var(--bs-light);
    font-size: 1.8rem;
    font-weight: 800;
  }
  .popover-body {
    font-size: 1.5rem;
  }
}

@media (max-width: 500px) {
  .popover-lg {
    max-width: 300px;
    min-width: 100px;
  }
}

.celebrations-text p {
  margin-top: 0;
  margin-bottom: 3px;
}

// VIDEO JS STYLES

.video-js .vjs-big-play-button {
  height: 85px;
  width: 84px;
  border-radius: 99em;
  background: none !important;
  border: none;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  content: "";
  background-image: url("../src/assets/play-button.svg");
  background-repeat: no-repeat;
}

.card.edit {
  border: solid 4px var(--gl-secondary) !important;
}

// NG SELECT OVERRIDES
// Update placeholder to match bootstrap default
.ng-select {
  .ng-select-container {
    .ng-value-container .ng-placeholder {
      color: #212529;
    }
    .ng-clear-wrapper {
      line-height: 1;
      text-align: right;
      padding-bottom: 6px;
      .ng-clear {
        font-size: 34px;
        line-height: 1;
      }
    }
  }
}

#resource-tags {
  .ng-select-container {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: rgba(var(--gl-secondary-rgb), 0.25);
  }
  .ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value {
    background: #fff;
    border: solid 1px var(--gl-secondary);
    font-weight: 700;
    font-size: 1.2rem;
  }
}

.category-select {
  .ng-select.ng-select-single .ng-select-container {
    min-height: 33px;
    height: 33px;
    font-size: 1.4rem;
  }
}

.fr-box .fr-counter {
  color: var(--gl-grey);
}

// modal styling

.resource-share-modal {
  .modal-content {
    height: 85vh;
    overflow-y: auto;
  }
}

.modal-header {
  .modal-title {
    font-weight: 500;
  }
  button {
    position: absolute;
    right: 15px;
  }
}

.testing-box {
  border: dotted 2px red;
  border-radius: 8px;
  margin: 10px 0;
  padding: 20px;
  font-size: 1.2rem;
  &.hidden {
    display: none;
  }
}

// froala

.fr-placeholder {
  padding: 6px 8px !important;
  line-height: 22.4px !important;
  font-size: 1.6rem !important;
  color: #6c757d !important;
  font-family: $font-family-sans-serif !important;
}

.fr-element p {
  font-family: $font-family-sans-serif;
  font-size: 1.6rem;
  color: #212529;
}

.fr-popup.fr-active {
  z-index: 1056 !important;
}

span.fr-emoticon.fr-emoticon-img {
  background-repeat: no-repeat !important;
  font-size: inherit;
  height: 1em;
  width: 1em;
  min-height: 20px;
  min-width: 20px;
  display: inline-block;
  margin: -0.1em 0.1em 0.1em;
  line-height: 1;
  vertical-align: middle;
}
