// BOOTSTRAP OVERRIDES

$font-family-sans-serif: greycliff-cf, system-ui, -apple-system, "Segoe UI",
  Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

///////////////////////////////////////
// 62.5% REM SPECIFIC OVERRIDES
// For reference: www.aleksandrhovhannisyan.com/blog/62-5-percent-font-size-trick/
// These changes below just get Bootstrap back to 'normal' after
// changing the base font size to 10px instead of 16px;
///////////////////////////////////////

// These values needed for rem changes below
$black: #000;
$text-muted: #495057 !default;
$border-width: 1px !default;
$input-btn-border-width: $border-width !default;
$input-border-width: $input-btn-border-width !default;
$input-height-border: $input-border-width * 2 !default;

// Calculate new value  = (oldvalue * 16) / 10
$spacer: 1.6rem !default; // 1
$paragraph-margin-bottom: 1.6rem !default; // 1
$grid-gutter-width: 2.4rem !default; // 1.5
$border-radius: 0.6rem !default; // .375rem
$border-radius-sm: 0.4rem !default; // .25rem
$border-radius-lg: 0.8rem !default; // .5rem
$border-radius-xl: 1.6rem !default; // 1rem
$border-radius-2xl: 3.2rem !default; // 2rem
$border-radius-pill: 80rem !default; // 50rem
$box-shadow: 0 0.8rem 1.6rem rgba($black, 0.15) !default;
$box-shadow-sm: 0 0.2rem 0.4rem rgba($black, 0.075) !default;
$box-shadow-lg: 0 1.6rem 4.8rem rgba($black, 0.175) !default;
$font-size-base: 1.6rem !default; // 1
$display-font-sizes: (
  // 5rem
  1: 8rem,
  // 4.5rem
  2: 7.2rem,
  // 4rem
  3: 6.4rem,
  // 3.5rem
  4: 5.6rem,
  // 3rem
  5: 4.8rem,
  // 2.5rem
  6: 4rem
) !default;
$legend-margin-bottom: 0.8rem !default; // .5rem
$legend-font-size: 2.4rem !default; // 1.5rem
$list-inline-padding: 0.8rem !default; // .5rem
$table-cell-padding-y: 0.8rem !default; // .5rem
$table-cell-padding-x: 0.8rem !default; // .5rem
$table-cell-padding-y-sm: 0.4rem !default; // .25rem
$table-cell-padding-x-sm: 0.4rem !default; // .25rem
$input-btn-padding-y: 0.6rem !default; // .375rem
$input-btn-padding-x: 1.2rem !default; // .75rem
$input-btn-focus-width: 0.4rem !default; // .25rem
$input-btn-padding-y-sm: 0.4rem !default; // .25rem
$input-btn-padding-x-sm: 0.8rem !default; // .5rem
$input-btn-padding-y-lg: 0.8rem !default; // .5rem
$input-btn-padding-x-lg: 1.6rem !default; // 1rem
$form-text-margin-top: 0.4rem !default; // .25rem
$form-label-margin-bottom: 0.8rem !default; // .5rem
$form-color-width: 4.8rem !default; // 3rem
$form-check-margin-bottom: 0.2rem !default; // .125rem
$form-check-inline-margin-end: 1.6rem !default; // 1rem
$form-range-track-height: 0.8rem !default; // .5rem
$form-range-track-border-radius: 1.6rem !default; // 1rem
$form-range-thumb-width: 1.6rem !default; // 1rem
$form-range-thumb-border-radius: 1.6rem !default; // 1rem
$form-range-thumb-box-shadow: 0 0.16rem 0.4rem rgba($black, 0.1) !default; // 0 .1rem .25rem
$form-floating-height: add(5.6rem, $input-height-border) !default; // 3.5rem
$form-floating-padding-y: 1.6rem !default; // 1rem
$form-floating-input-padding-t: 2.6rem !default; // 1.625rem
$form-floating-input-padding-b: 1rem !default; // .625rem
$form-floating-label-transform: scale(0.85) translateY(-0.8rem)
  translateX(0.24rem) !default; // -.5rem   .15rem
$nav-link-padding-y: 1.1172rem !default; // .5rem
$nav-link-padding-x: 1.6rem !default; // 1rem
$navbar-nav-link-padding-x: 0.8rem !default; // .5rem
$navbar-brand-margin-end: 1.6rem !default; // 1rem
$navbar-toggler-padding-y: 0.4rem !default; // .25rem
$navbar-toggler-padding-x: 1.2rem !default; // .75rem
$dropdown-min-width: 16rem !default; // 10rem
$dropdown-padding-y: 0.8rem !default; // .5rem
$dropdown-spacer: 0.2rem !default; // .125rem
$pagination-padding-y: 0.6rem !default; // .375rem
$pagination-padding-x: 1.2rem !default; // .75rem
$pagination-padding-y-sm: 0.4rem !default; // .25rem
$pagination-padding-x-sm: 0.8rem !default; // .5rem
$pagination-padding-y-lg: 1.2rem !default; // .75rem
$pagination-padding-x-lg: 2.4rem !default; // 1.5rem
$accordion-padding-y: 1.6rem !default; // 1rem
$accordion-padding-x: 2rem !default; // 1.25rem
$accordion-icon-width: 2rem !default; // 1.25rem
$tooltip-arrow-width: 1.28rem !default; // .8rem
$tooltip-arrow-height: 0.64rem !default; // .4rem
$popover-header-padding-y: 0.8rem !default; // .5rem
$popover-arrow-width: 1.6rem !default; // 1rem
$popover-arrow-height: 0.8rem !default; // .5rem
$toast-padding-x: 1.2rem !default; // .75rem
$toast-padding-y: 0.8rem !default; // .5rem
$toast-font-size: 1.4rem !default; // .875rem
$modal-footer-margin-between: 0.8rem !default; // .5rem
$modal-dialog-margin: 0.8rem !default; // .5rem
$modal-dialog-margin-y-sm-up: 2.8rem !default; // 1.75rem
$alert-margin-bottom: 1.6rem !default; // 1rem
$progress-height: 1.6rem !default; // 1rem
$thumbnail-padding: 0.4rem !default; // .35rem
$breadcrumb-item-padding-x: 0.8rem !default; // .5rem
$breadcrumb-margin-bottom: 1.6rem !default; // 1rem
$carousel-caption-padding-y: 2rem !default; // 1.25rem
$carousel-caption-spacer: 2rem !default; // 1.25rem
$carousel-control-icon-width: 3.2rem !default; // 2rem
$spinner-width: 3.2rem !default; // 2rem
$spinner-width-sm: 1.6rem !default; // 1rem
$kbd-padding-y: 0.3rem !default; // .1875rem
$kbd-padding-x: 0.6rem !default; // .375rem
